import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Lauten Family`}</strong>{` is one of the most prominent of the `}<a parentName="p" {...{
        "href": "/Aljiedum%20Noble%20Houses",
        "title": "Aljiedum Noble Houses"
      }}>{`Aljiedum Noble Houses`}</a>{`. While they do not oversee any of Aljieudum’s `}<a parentName="p" {...{
        "href": "/Aljiuedum%20District",
        "title": "Aljiuedum District"
      }}>{`Districts`}</a>{`, many members of the family, including the Family’s current patriarch, `}<a parentName="p" {...{
        "href": "/Halver%20Lauten",
        "title": "Halver Lauten"
      }}>{`Halver Lauten`}</a>{`, have high-rankings positions in Aljiuedum’s `}<a parentName="p" {...{
        "href": "/Chamber%20of%20Nobles",
        "title": "Chamber of Nobles"
      }}>{`Chamber of Nobles`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      